
import { createMuiTheme, makeStyles, Theme } from '@material-ui/core';
import { getCookieValue } from 'dynamic-form-components/lib/service';
import { CapitalizedHeading, Heading } from '../common/index';
import {
    commonFormTranslations,
    contextPath,
    countryCodeToCurrenccySymbol,
    donationsStatusText,
    DONATION_TYPE_MAP,
    DONATION_TYPE_VALUES,
    monetaryDonationStatuses,
    recurringGoodsDonationStatuses
} from '../../constants';
import { Column } from 'primereact/column';
import { getDateOnlyForLocale, getFormattedDate } from '../common/DateUtils';
import { Calendar } from 'primereact/calendar';
import { numberFormat } from '../common/utils';
import GlobalDonationDataService from 'src/service';
import { Dropdown } from 'primereact/dropdown';
import SelectOptionTemplateComponent from './oneTimeGoods/SelectOptionTemplateComponent';
import { Button } from 'primereact/button';
import { exportToExcel, getDonationDataById, IDashboardFilter } from '../../service/apiService';
import { oneTimeFoodDonationStatuses } from '../common/oneTimeFoodDonationStatuses';
import { fetchSpaceInternationalDashboardColumns } from './gdmsSpaceDonation/SpaceInterantionalDonationColumns';
import { baseSubContextUrl } from './CommonComponents/commonApi';
import { fetchServiceDonationDashboardColumns } from './serviceDonation/serviceDonationColumns';

const inputTextColor = 'var(--inputTextColor)';
export const appFontFamily = ' var(--appFont)';

export const storeNumber: number | string = getCookieValue('storeNumber');

export const sourceApp = 'EXTERNAL';
export const marketCode = getCookieValue('marketCode');
export const loggedInUser = getCookieValue('userId');
export const homeOfficeDashboardLink = `${contextPath}/dashboard/one-time-goods?type=one-time-goods`
export const oneTimeGoodsHeadingHome = 'oneTimeGoodsDonation.nav.home'
export const oneTimeGoodsLink = 'oneTimeGoodsDonation.oneTimeGoodsDonation'
export const orgProfileLink = 'organizationProfileDetails.common.orgProfileHeader'
export const countryCodesCookieValue = getCookieValue('countryCodes') ? decodeURIComponent(getCookieValue('countryCodes')) : '';
export const currency = countryCodeToCurrenccySymbol[countryCodesCookieValue];

export const defaultCountryCode = countryCodeToCurrenccySymbol[countryCodesCookieValue];
export const getCurrencySymbol = (countryCode: string = defaultCountryCode) => {
    return countryCodeToCurrenccySymbol[countryCode];
}
export const ngoAcknowledgementReviewBreadcrumbs = [
    {
        name: oneTimeGoodsHeadingHome,
        path: '/',
    },
    {
        name: oneTimeGoodsLink,
        path: homeOfficeDashboardLink,
    },
    {
        name: 'oneTimeGoodsDonation.nav.reviewByDonationId',
        path: homeOfficeDashboardLink,
        applyFormat: true
    },
    {
        name: 'oneTimeGoodsDonation.header',
        active: true
    }
];

export const homeOfficeReviewBreadcrumbs = [
    {
        name: oneTimeGoodsHeadingHome,
        path: '/',
    },
    {
        name: oneTimeGoodsLink,
        path: homeOfficeDashboardLink,
    },
    {
        name: 'oneTimeGoodsDonation.nav.review',
        active: true,
        applyFormat: true
    }
];

export const orgProfileBreadcrumbsData = [
    {
        name: oneTimeGoodsHeadingHome,
        path: '/',
    },
    {
        name: orgProfileLink,
        active: true,
        applyFormat: false
    }
];

export const breadcrumbsData = [
    {
        name: oneTimeGoodsHeadingHome,
        path: '/',
    },
    {
        name: oneTimeGoodsLink,
        path: '/store-app/view/one-time-goods',
    },
    {
        name: 'oneTimeGoodsDonation.nav.requestNew',
        active: true,
        applyFormat: false
    }
];

export const styles: any = (_theme: any) => ({
    selectRoot: {
        color: inputTextColor,
        fontFamily: appFontFamily,
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: `${inputTextColor} !important`
    },
    selectFormControl: {
        color: inputTextColor,
        minWidth: '230px',
        fontFamily: appFontFamily,
        backgroundColor: '#fff'
    },
    textFormControl: {
        color: inputTextColor,
        minWidth: '100%',
        fontFamily: appFontFamily,
        backgroundColor: '#fff',
        marginTop: '1rem',
        marginRight: '1rem',
        marginBottom: '1rem'
    },
    selectLabel: {
        color: inputTextColor,
        fontFamily: appFontFamily,
        marginLeft: '10px'
    },
    menuItemRoot: {
        color: inputTextColor,
        fontFamily: appFontFamily
    },
    menuItemselected: {
        color: inputTextColor
    },
    buttonGroup: {
        display: 'inline-flex'
    },
    highlight: {
        backgroundColor: marketCode && marketCode.trim().toUpperCase() === 'GB' ?
            '#228B22 !important' : '#041E42 !important',
        '& *': {
            color: 'rgba(255, 255, 255, 0.8)',
        },
        fontFamily: appFontFamily
    },
    createNewBtn: {
        float: 'right',
        marginRight: '100px',
        marginTop: '12px'
    },
    searchOrgTextFiled: {
        minHeight: '55px'
    },
    buttonStyles: {
        marginBottom: 20
    },
    closeBtn: {
        fontSize: '18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color: 'red'
        }
    },
    overrides: {
        MuiInputLabel: {
            outlined: {
                backgroundColor: '#fff',
                paddingLeft: 2,
                paddingRight: 2
            }
        }
    }
});

export const validatePositiveNumber = (value: number, selectedLang: string) => {
    return (value > 0 || value > 0.0) ? true : `${commonFormTranslations.validationMsg.positiveNumbersOnly[selectedLang]}`;
};

export const FormBlockHeading = (props: any) => (
    <div className={'row'} style={{ marginBottom: '10px', marginTop: '20px' }}>
        <div className={'col-md-12'}>
            <Heading
                id={`ngo_od_heading${props.heading}`}
                className={`h16_bold_title ngo_od_heading${props.heading}`}
                level={'h6'}
            >{props.heading}</Heading>
        </div>
    </div>
);

export const PageHeading = (props: any) => <CapitalizedHeading
    id={'dgsa_heading'}
    level={'h3'}
    className={'page_heading dgsa_heading'}
>{props.heading}</CapitalizedHeading>;

export const PageSubHeading = (props: any) =>
    <Heading
        id={'dgsp_heading'}
        className={'h14_title mb-30 dgsp_heading'}
        level={'h6'}
    >
        {props.heading}
    </Heading>;

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#041E42',
        },
        secondary: {
            main: '#ff9100',
        }
    },
    typography: {
        body1: {
            fontFamily: appFontFamily
        }
    },
    overrides: {
        MuiCheckbox: {
            root: {
                '&$checked': {
                    color: marketCode && marketCode.trim().toUpperCase() === 'GB' ?
                        '!important' : '#041E42 !important',
                }
            }
        },
        MuiPaper: {
            root: {
                width: 'auto !important'
            }
        },
        MuiSelect: {
            selectMenu: {
                minWidth: '150px',
                whiteSpace: 'pre-wrap'
            }
        },
        MuiInputBase: {
            input: {
                minWidth: '80px'
            }
        }
    }
});

export const wordLengthValidation = (value: any, message: any, wordLength: number) => {
    return (value && value.length > wordLength) ? message : true
};

export const useStyles = makeStyles((_theme: Theme) => ({
    selectRoot: {
        color: inputTextColor,
        fontFamily: appFontFamily
    },
    selectFormControl: {
        color: 'rgba(0, 0, 0, 0.54) !important',
        minWidth: '300px',
        fontFamily: appFontFamily,
        backgroundColor: '#fff',
        margin: '0 !important',
        fontSize: '14px !important',
    },
    selectLabel: {
        color: 'rgba(0, 0, 0, 0.54)  !important',
        fontFamily: appFontFamily,
        marginLeft: '10px',
        backgroundColor: '#fff',
        fontSize: '14px !important',
    },
    selectIcon: {
        color: 'rgba(0, 0, 0, 0.54)  !important'
    },
    menuItemRoot: {
        color: 'rgba(0, 0, 0, 0.54) ',
        fontFamily: appFontFamily,
        minWidth: '250px',
    },
    menuItemselected: {
        color: 'rgba(0, 0, 0, 0.54) '
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    formContainer: {
        padding: '10px 20px 0px 20px;',
        width: '650px',
    },
    disabled: {
        opacity: '0.8 !important',
        cursor: 'not-allowed',
        backgroundColor: '#e9ecef'
    },
    formRadioRoot: {
        color: '#1a0dab',
        '&$checked': {
            color: '#4B8DF8'
        }
    },
    formRadioChecked: {}
}));


export const getFormDataInFormat = (formData: any, searchOrgByNameState: any) => {
    const formDataInFormat = {
        countryCode: countryCodesCookieValue,
        storeNumber: Number(storeNumber),
        sourceApp: 'STORE',
        donationDetails: {
            donationTitle: formData?.donationTitle ? formData.donationTitle : '',
            donationDetailDescription: formData?.donationDetailDescription ? formData.donationDetailDescription : '',
            requestAmount: formData?.requestAmount ? parseFloat(formData.requestAmount) : 0,
        },
        certification: {
            date: formData?.certification?.date,
            name: formData?.certification?.name,
            signature: formData?.certification?.signature,
        },
        organization: {
            address: {
                ...formData?.address,
                country: formData?.countryCode?.toUpperCase()
            },
            primaryUser: {
                ...formData?.primaryOrgUser,
                prefLanguage: formData?.prefLanguage,
            },
            name: formData?.orgLegalName ? formData.orgLegalName : '',
            orgId: searchOrgByNameState?.organizationData?.id ? searchOrgByNameState?.organizationData?.id : null,
            type: 'org_type_ngo'
        }
    };
    return formDataInFormat;
};

export const getNgoAcknowledgementUpdatePayload = (formData: any, ngoPageData: any, comments: string) => {
    return {
        donationId: ngoPageData?.goodsOneTimeDonation?.id,
        certification: {
            date: formData?.certification?.date,
            name: formData?.certification?.name,
            signature: formData?.certification?.signature
        },
        waaSTask: {
            formProperties: ['acknowledge'],
            requestId: ngoPageData?.waaSTaskMessage?.requestId,
            taskId: ngoPageData?.waaSTaskMessage?.taskId,
            taskName: ngoPageData?.waaSTaskMessage?.taskName,
            variables: { userAction: 'acknowledge' }
        },
        comments
    }
}

export const getUpdateRequestPayload = (
    formData: any, ngoPageData: any, comments: any, userAction: string
) => {
    const orgData = { ...formData?.organization }
    const donationDetail = { ...formData?.donationDetail }
    const appendixA = { ...ngoPageData?.goodsOneTimeDonation }
    const appendixBOrg = { ...ngoPageData?.goodsOneTimeDonation?.appendixB?.organization }
    delete formData?.organization
    delete formData?.donationDetail
    delete appendixA?.appendixB

    return {
        goodsOneTimeDonation: {
            ...appendixA,
            additionalDetails: {
                ...ngoPageData?.goodsOneTimeDonation?.additionalDetails,
                appendixBIsFilled: true,
                isAppendixBFilledByNgo: true
            },
            appendixB: {
                organization: {
                    ...formData,
                    ...orgData,
                    id: appendixBOrg?.id,
                    countryCode: appendixBOrg?.countryCode,
                    additionalOrgUsers: appendixBOrg?.additionalOrgUsers,
                    additionalOrgUserEmails: appendixBOrg?.additionalOrgUserEmails,
                    blocked: appendixBOrg?.blocked,
                    registered: appendixBOrg?.registered,
                    completedProfile: appendixBOrg?.completedProfile,
                    orgAdditionalDetails: appendixBOrg?.orgAdditionalDetails,
                    externalSource: appendixBOrg?.externalSource,
                    gdmsRequestSourceApp: appendixBOrg?.gdmsRequestSourceApp,
                    businessCode: appendixBOrg?.businessCode,
                    dnbStatus: appendixBOrg?.dnbStatus,
                    dnbTrackingStatus: appendixBOrg?.dnbTrackingStatus,
                    paymentStatus: appendixBOrg?.paymentStatus,
                    organizationType: appendixBOrg?.organizationType,
                    governmentCategory: appendixBOrg?.governmentCategory,
                    donationsExpectedFromGovt: appendixBOrg?.donationsExpectedFromGovt,
                    isPrimary: appendixBOrg?.isPrimary,
                    profileChanged: appendixBOrg?.profileChanged,
                    presentInDNB: appendixBOrg?.presentInDNB,
                    consentChanged: appendixBOrg?.consentChanged,
                    toBeSentForVetting: appendixBOrg?.toBeSentForVetting,
                    actionLogs: appendixBOrg?.actionLogs,
                    underRenewal: appendixBOrg?.underRenewal,
                    appendixBFillReason: appendixBOrg?.appendixBFillReason,
                    caUserCertificateSignee: appendixBOrg?.caUserCertificateSignee,
                    caUserCertificateSignedDate: appendixBOrg?.caUserCertificateSignedDate,
                    active: appendixBOrg?.active,
                    audit: appendixBOrg?.audit
                },
                donationDetail: {
                    ...ngoPageData?.goodsOneTimeDonation?.appendixB?.donationDetail,
                    ...donationDetail,
                    govtSuggestion: formData?.govtSuggestion,
                    govtSuggestionDetails: formData?.govtSuggestionDetails
                }
            },
            comments
        },
        waaSTask: {
            taskId: ngoPageData?.waaSTaskMessage?.taskId,
            requestId: ngoPageData?.waaSTaskMessage?.requestId,
            taskName: ngoPageData?.waaSTaskMessage?.taskName,
            variables: { userAction }
        }
    }
}
export const getFormattedAppendixBData = (formData: any, appendixB: any, preferredLanguageCode: any) => {

    const donationDetail = { ...formData?.donationDetail }
    const country = formData?.organization?.country
    const countryCode = appendixB?.countryCode

    delete formData?.donationDetail
    return {
        donationDetail: {
            ...donationDetail,
            govtSuggestion: formData?.govtSuggestion,
            govtSuggestionDetails: formData?.govtSuggestionDetails,
        },
        organization: {
            id: appendixB?.id,
            active: appendixB?.active,
            audit: { ...appendixB?.audit },
            orgLegalName: formData?.organization?.orgLegalName,
            orgLegalNamePhonetic: appendixB?.orgLegalNamePhonetic,
            dnbPId: appendixB?.dnbPId,
            taxId: formData?.organization?.taxId ? formData?.organization?.taxId : appendixB?.taxId,
            address: {
                ...formData?.organization?.address,
                city: formData?.organization?.address?.city ? formData?.organization?.address?.city :
                    appendixB?.address?.city,
                province: formData?.organization?.address?.province ? formData?.organization?.address?.province :
                    appendixB?.address?.province
            },
            telephone: formData?.organization?.telephone,
            orgEmail: formData?.organization?.orgEmail,
            website: formData?.organization?.website,
            primaryOrgUser: {
                ...formData?.primaryOrgUser,
                prefLanguage: preferredLanguageCode,
                countryOfResidence: formData?.primaryOrgUser?.countryOfResidence ? formData?.primaryOrgUser?.countryOfResidence :
                    appendixB?.primaryOrgUser?.countryOfResidence,
                birthYear: formData?.primaryOrgUser?.birthYear ? formData?.primaryOrgUser?.birthYear :
                    appendixB?.primaryOrgUser?.birthYear
            },
            additionalOrgUsers: appendixB?.additionalOrgUsers,
            additionalOrgUserEmails: appendixB?.additionalOrgUserEmails,
            orgUploadedFiles: appendixB?.orgUploadedFiles,
            establishedDate: formData?.organization?.establishedDate,
            missionAndPurpose: formData?.organization?.missionAndPurpose,
            geographicCoverage: formData?.organization?.geographicCoverage,
            authorized: formData?.authorized,
            issueReceipts: formData?.issueReceipts,
            govtEntityRelation: formData?.govtEntityRelation,
            govtEntityRelationDetails: formData?.govtEntityRelationDetails,
            govtOfficialRelation: formData?.govtOfficialRelation,
            govtOfficialRelationDetails: formData?.govtOfficialRelationDetails,
            politicalActivity: formData?.politicalActivity,
            politicalActivityDetails: formData?.politicalActivityDetails,
            corruptionOrBribery: formData?.corruptionOrBribery,
            corruptionOrBriberyDetails: formData?.corruptionOrBriberyDetails,
            pastDonation: formData?.pastDonation,
            pastDonationDetails: formData?.pastDonationDetails,
            prevDeniedDonation: formData?.prevDeniedDonation,
            prevDeniedDonationDetails: formData?.prevDeniedDonationDetails,
            boardMembers: formData?.boardMembers,
            executiveOfficers: formData?.executiveOfficers,
            keyPersons: formData?.keyPersons,
            govtOrPoliticalOffice: formData?.govtOrPoliticalOffice,
            govtOrPoliticalOfficeDetails: formData?.govtOrPoliticalOfficeDetails,
            govtOrPoliticalOfficeRelative: formData?.govtOrPoliticalOfficeRelative,
            govtOrPoliticalOfficeRelativeDetails: formData?.govtOrPoliticalOfficeRelativeDetails,
            certificateSignee: { ...formData?.certificateSignee },
            certificateSignedDate: formData?.certificateSignedDate,
            consentSignee: { ...formData?.consentSignee },
            consentSignedDate: formData?.consentSignedDate,
            blocked: appendixB?.blocked,
            registered: appendixB?.registered,
            completedProfile: appendixB?.completedProfile,
            creatorWinNbr: appendixB?.creatorWinNbr,
            orgAdditionalDetails: {
                isRegisteredNew: false
            },
            externalSource: appendixB?.externalSource,
            gdmsRequestSourceApp: appendixB?.gdmsRequestSourceApp,
            country,
            countryCode,
            businessCode: appendixB?.businessCode,
            languageCode: appendixB?.languageCode,
            dnbStatus: { ...appendixB?.dnbStatus },
            dnbTrackingStatus: { ...appendixB?.dnbTrackingStatus },
            paymentStatus: { ...appendixB?.paymentStatus },
            organizationType: { ...appendixB?.organizationType },
            governmentCategory: { ...appendixB?.governmentCategory },
            profileChanged: appendixB?.profileChanged,
            donationsExpectedFromGovt: appendixB?.donationsExpectedFromGovt,
            donationsExpectedFromGovtDetail: appendixB?.donationsExpectedFromGovtDetail,
            migratedId: appendixB?.migratedId,
            actionLogs: appendixB?.actionLogs,
            notifyToAcUser: appendixB?.notifyToAcUser,
            presentInDNB: appendixB?.presentInDNB,
            consentChanged: appendixB?.consentChanged,
            toBeSentForVetting: appendixB?.toBeSentForVetting,
            sentForVettingDateTime: appendixB?.sentForVettingDateTime,
            markedForVettingDateTime: appendixB?.markedForVettingDateTime,
            userMarkedForVetting: appendixB?.userMarkedForVetting,
            underRenewal: appendixB?.underRenewal,
            appendixBFillReason: appendixB?.appendixBFillReason,
            caUserCertificateSignee: appendixB?.caUserCertificateSignee,
            caUserCertificateSignedDate: appendixB?.caUserCertificateSignedDate,
            migrated: appendixB?.migrated,
            primary: appendixB?.primary,
            certificationCheckBox1: formData?.certificationCheckBox1,
            certificationCheckBox2: formData?.certificationCheckBox2,
            certificationCheckBox3: formData?.certificationCheckBox3,
            consentCheckBox1: formData?.consentCheckBox1,
            consentCheckBox2: formData?.consentCheckBox2,
        },
        orgId: appendixB?.id,
    }

}

export const getMyPendingDonationStatuses = () => {
    return [
        'AWAITING_PAYMENT_ACKNOWLEDGMENT',
        'REQUEST_FOR_INFORMATION',
        'REQUEST_APPENDIX_B',
        'WAITING_FOR_NGO_ACKNOWLEDGEMENT',
        'WAITING_FOR_COLLECTION',
        'WAITING_FOR_APPENDIX_B',
        'WAITING_FOR_INFORMATION_FROM_ORGANIZATION',
        'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT',
        'DRAFT'
    ]
}

export const getAppenidxCOrgInfo = (data: any, certificationData: any) => {
    return certificationData ? { ...certificationData?.organizationInfo } : {
        city: data?.address?.city,
        country: data?.countryCode,
        legalName: data?.orgLegalName,
        postalCode: data?.address?.postalCode,
        state: data?.address?.province,
        streetAddress: data?.address?.addressLine1,
        telephone: data?.telephone
    }
}

export const home = 'oneTimeGoodsDonation.nav.home';
export const oneTimeFoodDonation = 'navigations.donationTypes.title.oneTimeFoodDonation';
export const dashboardPageBreadcrumbs = [
    {
        name: home,
        path: '/',
    },
    {
        name: oneTimeFoodDonation,
        active: true,
        applyFormat: false
    }
];

export const onDateFilterChange = (event: any, setDateFilter: any, dt: any) => {
    if (event.value !== null) {
        dt.current.filter(getFormattedDate(event.value), 'submittedDate', 'equals');
    } else {
        dt.current.filter(null, 'submittedDate', 'equals');
    }
    setDateFilter(event.value);
}

export const renderDateFilter = (dateFilter: any, t: any, setDateFilter: any, dt: any) => {
    return (
        <Calendar
            maxDate={new Date()}
            showIcon={false}
            showButtonBar={true}
            value={dateFilter}
            onChange={(event: any) => onDateFilterChange(event, setDateFilter, dt)}
            placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.requestDate')}
            dateFormat='dd-MM-yy'
            className='p-column-filter'
        />
    );
}
export const filterDate = (value: any, filter: any) => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }
    if (value === undefined || value === null) {
        return false;
    }
    return value === formatDate(filter);
}
export const formatDate = (date: Date) => {
    return getFormattedDate(date);
}
export const amountBodyTemplate = (rowData: any) => {
    const requestAmount = rowData?.appendixA?.requestAmount
    return <div>
        {requestAmount ? numberFormat(requestAmount, rowData?.countryCode) : 'NA'}
    </div>
}
export const downloadProjectDocs = (donationDetails: any, setLoading: any, getAppendixTypes: any,
    setShowAppendixDownloadModal: any, setDownloadAppendixProps: any, selectedLang: string,
    setAppendixMsg: any, t: any, hanldeError: any, setSelectedDonation: any, setError: any,
    downloadAppendixProps: any) => {
    setLoading(true)
    getAppendixTypes('food-onetime', donationDetails?.id, 'DONATION_OF_FOOD_ONE_TIME').then((res: any) => {
        setLoading(false)
        if (res?.length > 0) {
            setShowAppendixDownloadModal(true)
            setDownloadAppendixProps({
                countryCode: donationDetails?.countryCode,
                donationDetails,
                appendixTypeList: res,
                showDialog: true,
                closeDailog: () => closeAppendixModal(setLoading, setShowAppendixDownloadModal,
                    setDownloadAppendixProps, downloadAppendixProps),
                onDownload: (data: any) => downloadAppendix(data, setDownloadAppendixProps, downloadAppendixProps,
                    setShowAppendixDownloadModal, setLoading, setError),
                errorMessage: '',
                selectedLang
            })
        } else {
            setShowAppendixDownloadModal(false)
            setAppendixMsg(t('common.infoMsg.appendixMsg'))
        }
    }).catch((errorRes: any) => {
        setLoading(false)
        hanldeError(errorRes)
    })
    setSelectedDonation(donationDetails)
}
export const downloadAppendix = (data: any, setDownloadAppendixProps: any, downloadAppendixProps: any,
    setShowAppendixDownloadModal: any, setLoading: any, setError: any) => {
    setShowAppendixDownloadModal(false)
    const payLoad = {
        ...data,
        donation: { ...data?.donation },
        donationType: 'DONATION_OF_FOOD_ONE_TIME',
        langCode: data?.langCode
    }
    // @ts-ignore
    setDownloadAppendixProps({ ...downloadAppendixProps, showDialog: false })
    setLoading(true)
    GlobalDonationDataService.downloadProjectFile(payLoad).then((_res) => {
        setLoading(false)
    }).catch((err: any) => {
        setShowAppendixDownloadModal(false)
        setLoading(false)
        setError(err?.response.data)
    })
}
export const closeAppendixModal = (setLoading: any, setShowAppendixDownloadModal: any,
    setDownloadAppendixProps: any, downloadAppendixProps: any) => {
    setLoading(false)
    setShowAppendixDownloadModal(false)
    setDownloadAppendixProps({ ...downloadAppendixProps, showDialog: false })
}
export const viewStoreDonationRequest = (orgDetail: any, addDataToStore: any, ngoAcknowledgementReviewURL: any,
    reviewDonationUrl: any, history: any) => {
    const payload: any = { value: orgDetail, key: 'ONE_TIME_FOOD' }
    addDataToStore(payload)
    orgDetail?.currentStatusCode === 'WAITING_FOR_CA_ACKNOWLEDGEMENT' ?
        history.push(ngoAcknowledgementReviewURL) :
        history.push(reviewDonationUrl);
}
export const projectStatusBody = (rowData: any, selectedLang: string) => {
    const statusText = donationsStatusText?.ALL[selectedLang][rowData?.currentStatusCode] ?? 'NA'
    return (<>{statusText}</>)
}
export const recepientTypeBody = (rowData: any, selectedLang: string) => <>{rowData?.recipientType?.language?.[selectedLang] ?
    rowData?.recipientType?.language?.[selectedLang] : 'NA'}</>
export const foodOneTimeColumnDetails = (t: any, dt: any,
    dateFilter: any, selectedLang: string,
    setLoading: any, getAppendixTypes: any, setShowAppendixDownloadModal: any, setDownloadAppendixProps: any,
    setAppendixMsg: any, hanldeError: any, setSelectedDonation: any, setError: any, setDateFilter: any, downloadAppendixProps: any,
    addDataToStore: any, ngoAcknowledgementReviewURL: any, reviewDonationUrl: any, history: any,
    selectedStatus: any, setSelectedStatus: any) => {
    return ([
        {
            field: 'id',
            header: t('commonDashboardLabels.columns.projectId'),
            sortable: true,
            sortField: 'id',
            filter: true,
            filterField: 'id',
            filterMatchMode: 'equals',
            filterPlaceholder: t('commonDashboardLabels.columns.filtersPlaceHolderTxt.projectId'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        // @ts-ignore
                        onClick={() => viewStoreDonationRequest(rowData, addDataToStore,
                            ngoAcknowledgementReviewURL, reviewDonationUrl, history)}>
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>{rowData?.id}</span>
                    </button>
                </div>
            },
            style: { width: '14em' }
        },
        {
            field: 'submittedDate',
            header: t('commonDashboardLabels.columns.requestDate'),
            sortable: true,
            sortField: 'submittedDate',
            filter: true,
            body: (rowData: any) => rowData?.submittedDate ? getDateOnlyForLocale(rowData?.submittedDate) : 'NA',
            filterField: 'submittedDate',
            filterMatchMode: 'custom',
            filterFunction: filterDate,
            filterElement: renderDateFilter(dateFilter, t, setDateFilter, dt),
            style: { width: '15em' }
        },
        {
            field: 'organization.orgLegalName',
            header: t('commonDashboardLabels.columns.orgLegalName'),
            sortable: false,
            sortField: 'orgLegalName',
            filter: true,
            filterField: 'orgLegalName',
            filterPlaceholder: t('commonDashboardLabels.columns.filtersPlaceHolderTxt.orgName'),
            body: (rowData: any) => {
                console.log(rowData)
                return rowData.organization?.orgLegalName ?? '_'
            },
            style: { width: '20em' }
        },
        {
            field: 'organization.taxId',
            header: ('commonDashboardLabels.columns.taxId'),
            sortable: false,
            filter: true,
            sortField: 'taxId',
            filterField: 'taxId',
            filterPlaceholder: t('commonDashboardLabels.columns.filtersPlaceHolderTxt.taxId'),
            body: (rowData: any) => rowData?.organization?.taxId ? rowData?.organization?.taxId : 'NA',
            style: { width: '14em' },
        },
        {
            field: 'appendixA.requestAmount',
            header: ('commonDashboardLabels.columns.approvedAmount'),
            sortable: true,
            sortField: 'requestAmount',
            body: amountBodyTemplate,
            style: { width: '14em' }
        },
        {
            field: `currentStatusCode`,
            header: t('commonDashboardLabels.columns.projectStatus'),
            sortable: false,
            filter: true,
            filterField: 'currentStatusCode',
            filterElement: statusFilterElement(selectedStatus, dt, setSelectedStatus, t, selectedLang),
            body: (rowData: any) => projectStatusBody(rowData, selectedLang),
            style: { width: '14em' },
        },
        {
            field: 'appendixA.donationTitle',
            header: t('commonDashboardLabels.columns.projectTitle'),
            sortable: false,
            style: { width: '14em' },
        },
        {
            field: 'appendixA.projectDocs',
            header: t('commonDashboardLabels.columns.projectDocs'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        onClick={() => downloadProjectDocs(rowData, setLoading, getAppendixTypes,
                            setShowAppendixDownloadModal, setDownloadAppendixProps, selectedLang, setAppendixMsg, t,
                            hanldeError, setSelectedDonation, setError, downloadAppendixProps)}
                    >
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>
                            {t('commonDashboardLabels.projectDocsTxt')}
                        </span>
                    </button>
                </div>
            },
            sortable: false,
            style: { width: '14em' },
        },
        {
            field: 'sourceApp',
            header: t('commonDashboardLabels.columns.requestSource'),
            body: (rowdData: any) => rowdData?.sourceApp?.toLocaleLowerCase() ? rowdData.sourceApp.toLocaleLowerCase() : 'NA',
            sortable: false,
            style: { width: '14em' }
        },
        {
            field: `recipientType`,
            header: t('commonDashboardLabels.columns.recipientType'),
            sortable: false,
            body: (rowdData: any) => recepientTypeBody(rowdData, selectedLang),
            style: { width: '15em' }
        }
    ])
}


export const amountField = (rowData: any) => {
    const requestAmount = rowData?.donation?.donationDetail?.amount
    return <div>
        {requestAmount ? numberFormat(requestAmount, rowData?.countryCode || rowData?.donation?.countryCode) : 'NA'}
    </div>
}

export const moneyAndRecurringStatusFilterElement = (
    selectedStatus: any,
    dt: any,
    setSelectedStatus: any,
    t: any,
    selectedLang: string,
    donationType: string
) => {
    const statusOptions = DONATION_TYPE_VALUES.MONETARY === donationType ?
        monetaryDonationStatuses[selectedLang] : recurringGoodsDonationStatuses[selectedLang]
    return (
        <Dropdown
            value={selectedStatus}
            optionLabel='name'
            optionValue='code'
            options={statusOptions}
            onChange={(event: any) => onStatusFilterChange(event, dt, setSelectedStatus)}
            itemTemplate={countryItemTemplate}
            showClear={true}
            placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectStatus')}
            className='p-column-filter'
        />
    );
}

// used to download monetary and recurign goods appendicies
export const downloadMonetaryAppendix = (data: any, setShowAppendixDownloadModal: any, setLoading: any,
    setError: any, setDownloadAppendixProps: any, downloadAppendixProps: any, type: DONATION_TYPE_VALUES) => {
    setShowAppendixDownloadModal(false)
    const payLoad = {
        ...data,
        donation: { ...data?.donation },
        donationType: type,
        langCode: data?.langCode,
        date: data?.appendixcDate // this is only for recurring goods  appendix c download
    }
    setDownloadAppendixProps({ ...downloadAppendixProps, showDialog: false })
    setLoading(true)
    GlobalDonationDataService.downloadProjectFile(payLoad).then((_res) => {
        setLoading(false)
    }).catch((err: any) => {
        setShowAppendixDownloadModal(false)
        setLoading(false)
        setError(err?.response?.data)
    })
}

export const downloadDonationDocs = (donationDetails: any, setLoading: any, getAppendixTypes: any,
    setShowAppendixDownloadModal: any, setDownloadAppendixProps: any, selectedLang: string,
    setAppendixMsg: any, t: any, hanldeError: any, setSelectedDonation: any, setError: any,
    downloadAppendixProps: any, type: DONATION_TYPE_VALUES) => {
    setLoading(true)
    getAppendixTypes('donations', donationDetails?.donation?.id, type).then((res: any) => {
        setLoading(false)
        if (res?.length > 0) {
            setShowAppendixDownloadModal(true)
            setDownloadAppendixProps({
                countryCode: donationDetails?.donation?.countryCode,
                donationDetails,
                appendixTypeList: res,
                showDialog: true,
                closeDailog: () => closeAppendixModal(setLoading, setShowAppendixDownloadModal,
                    setDownloadAppendixProps, downloadAppendixProps),
                onDownload: (data: any) => downloadMonetaryAppendix({ ...data, ...data?.donation }, setShowAppendixDownloadModal, setLoading,
                    setError, setDownloadAppendixProps, downloadAppendixProps, type),
                errorMessage: '',
                selectedLang
            })
        } else {
            setShowAppendixDownloadModal(false)
            setAppendixMsg(t('common.infoMsg.appendixMsg'))
        }
    }).catch((errorRes: any) => {
        setLoading(false)
        hanldeError(errorRes)
    })
    setSelectedDonation(donationDetails)
}

export const getDonationStatusList = (donationType: string) => {
    let statusList: any
    switch (donationType) {

        case DONATION_TYPE_VALUES.GOODSANDFOOD:
            statusList = donationsStatusText?.[donationType]
            break;

        case DONATION_TYPE_VALUES.MONETARY:
            statusList = donationsStatusText?.[donationType]
            break

        default:
            statusList = donationsStatusText?.ALL
            break
    }
    return statusList
}

export const projectStatusField = (rowData: any, selectedLang: string, donationType: string) => {
    const donationStatusList = getDonationStatusList(donationType)
    const statusText = donationStatusList[selectedLang][rowData?.donation?.currentStatusCode] ?? 'NA'
    return (<>{statusText}</>)
}
const reviewMonetaryOrFoodsDonation = (orgDetail: any, addDataToStore: any, ngoAcknowledgementReviewURL: any,
    reviewDonationUrl: any, history: any, setLoading: any, setDownloadError: any, type: DONATION_TYPE_VALUES) => {
    setLoading(true)
    getDonationDataById(orgDetail?.donation?.id).then((_res: any) => {
        setLoading(false)
        const payload: any = {
            value: {
                donation: _res?.donation,
                currentStatusCode: _res?.donation?.currentStatusCode,
                waaSTaskMessage: _res?.waaSTaskMessage,
                id: orgDetail?.donation?.id,
                readOnly: true
            },
            // @ts-ignore
            key: DONATION_TYPE_MAP[type]
        }
        addDataToStore(payload)
        console.log('payload', payload)
        if ('errorMessage' in _res) {
            setLoading(false)
            setDownloadError(true)
        }
        orgDetail?.currentStatusCode === 'WAITING_FOR_CA_ACKNOWLEDGEMENT' ?
            history.push(ngoAcknowledgementReviewURL) :
            history.push(reviewDonationUrl);
    })
}
export const monetaryColumnDetails = (t: any, dt: any,
    dateFilter: any, selectedLang: string,
    setLoading: any, getAppendixTypes: any, setShowAppendixDownloadModal: any, setDownloadAppendixProps: any,
    setAppendixMsg: any, hanldeError: any, setSelectedDonation: any, setError: any, setDateFilter: any, downloadAppendixProps: any,
    addDataToStore: any, ngoAcknowledgementReviewURL: any, reviewDonationUrl: any, history: any,
    showStatusFilter: boolean, selectedStatus: any, setSelectedStatus: any, setDownloadError: any, type: DONATION_TYPE_VALUES) => {
    return ([
        {
            field: 'id',
            header: t('socialImpactReports.monetaryDashboard.columns.projectId'),
            sortable: true,
            sortField: 'id',
            filter: true,
            filterField: 'id',
            filterMatchMode: 'equals',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectId'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        // @ts-ignore
                        onClick={() => reviewMonetaryOrFoodsDonation(rowData, addDataToStore, ngoAcknowledgementReviewURL,
                            reviewDonationUrl, history, setLoading, setDownloadError, type)}>
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>{rowData?.donation?.id}</span>
                    </button>
                </div>
            },
            style: { width: '14em' }
        },
        {
            field: 'requestDate',
            header: t('oneTimeGoodsDonationDashboard.columns.requestDate'),
            sortable: true,
            sortField: 'audit.createdDtm',
            body: (rowData: any) => rowData?.donation?.audit?.createdDtm ? getDateOnlyForLocale(rowData.donation.audit.createdDtm) : 'NA',
            // filterField: 'requestDate',
            filterMatchMode: 'custom',
            filterFunction: filterDate,
            filterElement: renderDateFilter(dateFilter, t, setDateFilter, dt),
            style: { width: '15em' }
        },
        {
            field: 'organization.orgLegalName',
            header: t('orgMngmtTbl.cols.orgLegalName'),
            sortable: false,
            sortField: 'orgLegalName',
            filter: false,
            filterField: 'orgLegalName',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.orgName'),
            body: (rowData: any) => rowData?.donation?.organization?.orgLegalName,
            style: { width: '20em' }
        },
        {
            field: 'organization.taxId',
            header: t('orgMngmtTbl.cols.taxId'),
            sortable: false,
            filter: false,
            sortField: 'taxId',
            filterField: 'taxId',
            filterPlaceholder: t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.taxId'),
            body: (rowData: any) => rowData?.donation?.organization?.taxId ? rowData.donation.organization.taxId : 'NA',
            style: { width: '14em' },
        },
        {
            field: 'donation.donationTitle',
            header: t('oneTimeGoodsDonationDashboard.columns.projectTtile'),
            sortable: false,
            style: { width: '14em' },
        },
        {
            field: 'amount',
            header: t('tableData.amount'),
            sortable: true,
            sortField: 'donationDetail.amount',
            body: amountField,
            style: { width: '14em' }
        },
        {
            field: `currentStatusCode`,
            header: t('oneTimeGoodsDonationDashboard.columns.projectStatus'),
            sortable: false,
            filter: showStatusFilter,
            filterField: 'currentStatusCode',
            filterElement: moneyAndRecurringStatusFilterElement(selectedStatus, dt, setSelectedStatus, t, selectedLang, type),
            body: (rowData: any) => projectStatusField(rowData, selectedLang, type),
            style: { width: '14em' },
        },
        {
            field: 'appendixA.projectDocs',
            header: t('oneTimeGoodsDonationDashboard.columns.projectDocs'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        disabled={rowData?.donation?.currentStatusCode === 'DRAFT'}
                        onClick={() => downloadDonationDocs(rowData, setLoading, getAppendixTypes,
                            setShowAppendixDownloadModal, setDownloadAppendixProps, selectedLang, setAppendixMsg, t,
                            hanldeError, setSelectedDonation, setError, downloadAppendixProps, type)}
                    >
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}>
                            {t('oneTimeGoodsDonationDashboard.projectDocsTxt')}
                        </span>
                    </button>
                </div>
            },
            sortable: false,
            style: { width: '14em' },
        }
    ])
}
export const countryCodes = (countryCodesCookieValue && countryCodesCookieValue.length > 0) ? countryCodesCookieValue.split('/') : [];
export const countryCodeMap = (selectedLang: string) => countryCodes.map(code => ({ 'name': countryCodeTranslation[code][selectedLang], 'code': code }));
export const onCountryFilterChange = (event: any, dt: any, setSelectedCountry: any) => {
    dt.current.filter(event.value ?? '', 'countryCode', 'equals');
    setSelectedCountry(event.value);
}
export const countryItemTemplate = (option: any) => <SelectOptionTemplateComponent option={option} />
export const countryCodeTranslation: any = {
    'ALL': {
        en: 'All',
        es: 'All',
        fr: 'All',
        ja: 'All',
        zh: 'All'
    },
    'US': {
        en: 'US',
        es: 'US',
        fr: 'US',
        ja: 'US',
        zh: 'US'
    },
    'BR': {
        en: 'Brazil',
        es: 'Brazil',
        fr: 'Brazil',
        ja: 'Brazil',
        zh: 'Brazil'
    },
    'AR': {
        en: 'Argentina',
        es: 'Argentina',
        fr: 'Argentina',
        ja: 'Argentina',
        zh: 'Argentina'
    },
    'CA': {
        en: 'Canada',
        es: 'Canada',
        fr: 'Canada',
        ja: 'Canada',
        zh: 'Canada'
    },
    'CL': {
        en: 'Chile',
        es: 'Chile',
        fr: 'Chile',
        ja: 'Chile',
        zh: 'Chile'
    },
    'GT': {
        en: 'Guatemala',
        es: 'Guatemala',
        fr: 'Guatemala',
        ja: 'Guatemala',
        zh: 'Guatemala'
    },
    'HN': {
        en: 'Honduras',
        es: 'Honduras',
        fr: 'Honduras',
        ja: 'Honduras',
        zh: 'Honduras'
    },
    'MX': {
        en: 'Mexico',
        es: 'México',
        fr: 'México',
        ja: 'México',
        zh: 'México'
    },
    'NI': {
        en: 'Nicaragua',
        es: 'Nicaragua',
        fr: 'Nicaragua',
        ja: 'Nicaragua',
        zh: 'Nicaragua'
    },
    'GB': {
        en: 'United Kingdom',
        es: 'United Kingdom',
        fr: 'United Kingdom',
        ja: 'United Kingdom',
        zh: 'United Kingdom'
    },
    'CN': {
        en: 'China',
        es: 'China',
        fr: 'China',
        ja: 'China',
        zh: 'China'
    },
    'IN': {
        en: 'India',
        es: 'India',
        fr: 'India',
        ja: 'India',
        zh: 'India'
    },
    'JP': {
        en: 'Japan',
        es: 'Japan',
        fr: 'Japan',
        ja: 'Japan',
        zh: 'Japan'
    },
    'BW': {
        en: 'Botswana',
        es: 'Botswana',
        fr: 'Botswana',
        ja: 'Botswana',
        zh: 'Botswana'
    },
    'GH': {
        en: 'Ghana',
        es: 'Ghana',
        fr: 'Ghana',
        ja: 'Ghana',
        zh: 'Ghana'
    },
    'KE': {
        en: 'Kenya',
        es: 'Kenya',
        fr: 'Kenya',
        ja: 'Kenya',
        zh: 'Kenya'
    },
    'LS': {
        en: 'Lesotho',
        es: 'Lesotho',
        fr: 'Lesotho',
        ja: 'Lesotho',
        zh: 'Lesotho'
    },
    'MW': {
        en: 'Malawi',
        es: 'Malawi',
        fr: 'Malawi',
        ja: 'Malawi',
        zh: 'Malawi'
    },
    'MZ': {
        en: 'Mozambique',
        es: 'Mozambique',
        fr: 'Mozambique',
        ja: 'Mozambique',
        zh: 'Mozambique'
    },
    'NA': {
        en: 'Namibia',
        es: 'Namibia',
        fr: 'Namibia',
        ja: 'Namibia',
        zh: 'Namibia'
    },
    'NG': {
        en: 'Nigeria',
        es: 'Nigeria',
        fr: 'Nigeria',
        ja: 'Nigeria',
        zh: 'Nigeria'
    },
    'ZA': {
        en: 'South Africa',
        es: 'South Africa',
        fr: 'South Africa',
        ja: 'South Africa',
        zh: 'South Africa'
    },
    'SZ': {
        en: 'Swaziland',
        es: 'Swaziland',
        fr: 'Swaziland',
        ja: 'Swaziland',
        zh: 'Swaziland'
    },
    'TZ': {
        en: 'Tanzania',
        es: 'Tanzania',
        fr: 'Tanzania',
        ja: 'Tanzania',
        zh: 'Tanzania'
    },
    'UG': {
        en: 'Uganda',
        es: 'Uganda',
        fr: 'Uganda',
        ja: 'Uganda',
        zh: 'Uganda'
    },
    'ZM': {
        en: 'Zambia',
        es: 'Zambia',
        fr: 'Zambia',
        ja: 'Zambia',
        zh: 'Zambia'
    }
}
export const renderCountryCodeFilter = (
    selectedCountry: any, selectedLang: string, dt: any, setSelectedCountry: any, t: any) => {
    return (
        <Dropdown
            value={selectedCountry}
            optionLabel='name'
            optionValue='code'
            options={countryCodeMap(selectedLang)}
            onChange={(event: any) => onCountryFilterChange(event, dt, setSelectedCountry)}
            itemTemplate={(option: any) => countryItemTemplate(option)}
            showClear={true}
            placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.country')}
            className='p-column-filter'
        />
    );
}
export const exportHeader = (t: any, setLoading: any, lazyParams: any, setDownloadError: any, type: string) => {
    return (
        <div className='table-header'>
            <Button label={t('tableData.export')}
                icon='pi pi-download'
                className='p-button'
                onClick={() => exportDonationReport(setLoading, lazyParams, setDownloadError, type)}
            />
        </div>
    )
};
// For formating money and recurring goods request payload
export const getExcelRequestPayload = (payload: any, donationType: string) => {

    const globalSearchFilters = [];
    const defaultFilters: IDashboardFilter[] = [{ key: 'type', value: [donationType], matchType: 'IN' }]

    if (Object.keys(payload.filters).length > 0) {
        for (const property in payload.filters) {
            if (payload?.filters[property]?.value) {
                if (property === 'currentStatusCode' || property === 'organizationType') {
                    globalSearchFilters.push({ key: property, value: payload.filters[property].value, matchType: 'IN' })
                } else {
                    // this is for donation id filtering
                    if (property === 'id') {
                        defaultFilters.push({
                            key: property, matchType: 'CONTAINS',
                            value: +payload.filters[property].value
                        })
                    } else if (property === 'orgLegalName') {
                        defaultFilters.push({
                            key: 'organization.orgLegalName', matchType: 'CONTAINS',
                            value: payload.filters[property].value
                        })
                    } else if (property === 'migratedId') {
                        defaultFilters.push({
                            key: property, matchType: 'CONTAINS',
                            value: +payload.filters[property].value
                        })
                    } else if (property === 'submittedDate') {
                        defaultFilters.push({
                            key: 'audit.createdDtm', matchType: 'EQUALS',
                            value: payload.filters[property].value,
                            endValue: payload.filters[property].value,
                        })
                    } else {
                        defaultFilters.push({ key: property, value: payload.filters[property].value, matchType: 'CONTAINS' })
                    }
                }
            }
        }
    }
    return {
        globalSearchFilters,
        defaultFilters,
        searchText: payload.searchText,
        globalSearch: payload.globalSearch,
        pageSize: payload?.pageSize,
        pageNumber: payload?.pageNumber + 1,
        orderBy: payload?.orderBy
    }
}
export const exportDonationReport = (setLoading: any, lazyParams: any, setDownloadError: any, type: string) => {
    setLoading(true)
    const params = getFormattedParms(lazyParams)
    let endpoint;
    let fileName;
    let payload;
    if (type === DONATION_TYPE_VALUES.MONETARY || type === DONATION_TYPE_VALUES.GOODSANDFOOD) {
        endpoint = '/donations/excel/money'; // endpoint is same for both money and recurrging goods
        fileName = type;
        payload = { ...getExcelRequestPayload(lazyParams, type) };
    } 
    else if (type === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL || type === DONATION_TYPE_VALUES.SERVICE_DONATION) {
        endpoint = `/${baseSubContextUrl}/excel`;
        fileName = type;
        const defaultFilters = { "defaultFilters": [{ "key": "type", "value": [type], "matchType": "IN" }] }
        payload = { ...excelFormatPayload(lazyParams), ...defaultFilters };
    } else {
        endpoint = '/food-onetime/excel'
        fileName = 'onetimefoods'
        payload = { ...params }
    }

    exportToExcel({ payload, endpoint, fileName }).then((_res: any) => {
        setLoading(false)
        if ('errorMessage' in _res) {
            setDownloadError(true)
        }
    })
}

export const getFormattedParms = (payload: any) => {
    let params = {
        pageSize: payload?.pageSize,
        pageNumber: payload?.pageNumber,
    }
    const globalSearchFilters: any = []
    if (Object.keys(payload?.filters).length > 0) {
        for (const property in payload?.filters) {
            if (payload?.filters[property]?.value) {
                globalSearchFilters.push({ key: property, value: payload?.filters[property].value })
            }
        }
        // @ts-ignore
        params = { ...params, globalSearchFilters }
    }
    return params
}
export const onFilter = (event: any, lazyParams: any, setLazyParams: any) => {
    const _lazyParams = { ...lazyParams, ...event };
    _lazyParams.first = 0;
    setLazyParams(_lazyParams);
}
export const onPage = (event: any, lazyParams: any, setLazyParams: any) => {
    const _lazyParams = { ...lazyParams, ...event, pageSize: event.rows };
    if (event.page !== lazyParams.page || event.rows !== lazyParams.rows) {
        setLazyParams(_lazyParams);
    }
}

export const onSort = (event: any, lazyParams: any, setLazyParams: any) => {
    const _lazyParams = { ...lazyParams, ...event }
    setLazyParams({ ..._lazyParams, sortBy: event?.sortField });
}

export const donationColumnList = (t: any, dt: any, dateFilter: any, selectedLang: string,
    setLoading: any, getAppendixTypes: any, setShowAppendixDownloadModal: any,
    setDownloadAppendixProps: any, setAppendixMsg: any, hanldeError: any, setSelectedDonation: any, setError: any,
    setDateFilter: any, downloadAppendixProps: any, addDataToStore: any, ngoAcknowledgementReviewURL: any,
    reviewDonationUrl: any, history: any,
    showStatusFilter: boolean,
    selectedStatus: any,
    setSelectedStatus: any,
    setDownloadError: any,
    type: any, selectedDonationProps: any, setSelectedDonationProps: any, initialFilterList: any
) => {
    switch(type) {
        case DONATION_TYPE_VALUES.MONETARY:
        case DONATION_TYPE_VALUES.GOODSANDFOOD:
            return monetaryColumnDetails(t, dt, dateFilter, selectedLang, setLoading,
                getAppendixTypes, setShowAppendixDownloadModal, setDownloadAppendixProps, setAppendixMsg, hanldeError,
                setSelectedDonation, setError, setDateFilter, downloadAppendixProps, addDataToStore, ngoAcknowledgementReviewURL,
                reviewDonationUrl, history, showStatusFilter, selectedStatus, setSelectedStatus, setDownloadError, type)?.map((column: any) => <Column
                    key={column.field} {...{ ...column, header: t(column.header) }} />)
            break;
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return fetchSpaceInternationalDashboardColumns(selectedLang, history, dt, t,
                selectedStatus, setSelectedStatus, selectedDonationProps, setSelectedDonationProps, initialFilterList);
            break;
        case DONATION_TYPE_VALUES.SERVICE_DONATION: 
            return fetchServiceDonationDashboardColumns(selectedLang, history, dt, t,
                selectedStatus, setSelectedStatus, selectedDonationProps, setSelectedDonationProps, initialFilterList);
            break;
        default:
            return foodOneTimeColumnDetails(t, dt, dateFilter, selectedLang, setLoading,
                getAppendixTypes, setShowAppendixDownloadModal, setDownloadAppendixProps, setAppendixMsg, hanldeError,
                setSelectedDonation, setError, setDateFilter, downloadAppendixProps, addDataToStore, ngoAcknowledgementReviewURL,
                reviewDonationUrl, history, selectedStatus, setSelectedStatus)?.map((column: any) => <Column
                    key={column.field} {...{ ...column, header: t(column.header) }} />);
            break;
    }
}


export const onStatusFilterChange = (event: any, dt: any, setSelectedStatus: any) => {
    if (event?.value?.length > 0 && event?.value?.[0]) {
        dt.current.filter([event.value], 'currentStatusCode', 'equals');
    } else {
        dt.current.filter('', 'currentStatusCode', 'equals');
    }
    setSelectedStatus(event.value);
}

export const statusFilterElement = (selectedStatus: any, dt: any, setSelectedStatus: any, t: any, selectedLang: string) => {
    return (
        <Dropdown
            value={selectedStatus}
            optionLabel='name'
            optionValue='code'
            options={oneTimeFoodDonationStatuses[selectedLang]}
            onChange={(event: any) => onStatusFilterChange(event, dt, setSelectedStatus)}
            itemTemplate={countryItemTemplate}
            showClear={true}
            placeholder={t('oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectStatus')}
            className='p-column-filter'
        />
    );
}


const excelFormatPayload = (payload: any) => {
    let params = {
        pageSize: payload?.pageSize,
        pageNumber: payload?.pageNumber,
        searchText: "",
        globalSearch: false
    }

    const globalSearchFilters: any = []
    if (Object.keys(payload?.filters).length > 0) {
        for (const property in payload?.filters) {
            if (payload?.filters[property]?.value) {
                globalSearchFilters.push({ key: property, value: payload?.filters[property].value, "matchType": "IN" })
            }
        }
        // @ts-ignore
        params = { ...params, globalSearchFilters }
    }
    return params
}